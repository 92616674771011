import Colophon from './Colophon';
import React from 'react';
import {Box, HStack, IconButton, Link, Stack} from '@chakra-ui/react';
import {CONTAINER_GAP, CONTAINER_PADDING_X} from '../utils';
import {FaDiscord, FaInstagram, FaTwitter} from 'react-icons/fa';
import {Link as GatsbyLink} from 'gatsby';

function IconLink(props) {
  return (
    <IconButton
      fontSize="2xl"
      variant="ghost"
      as={Link}
      isExternal
      {...props}
    />
  );
}

export default function Footer() {
  return (
    <Box
      display={{md: 'flex'}}
      as="footer"
      maxW="container.xl"
      mx="auto"
      px={CONTAINER_PADDING_X}
      py={CONTAINER_GAP}
      color="gray.500"
    >
      <Colophon mr="auto" mb="2" />
      <Stack align={{md: 'flex-end'}}>
        <HStack spacing="4">
          <Link as={GatsbyLink} to="/privacy">
            Privacy Policy
          </Link>
          <Link as={GatsbyLink} to="/terms">
            Terms of Service
          </Link>
          <Link href="mailto:support@playback.rocks">Contact us</Link>
        </HStack>
        <div>
          <HStack mr={{md: -2}} ml={{base: -2, md: 0}}>
            <IconLink
              icon={<FaInstagram />}
              color="gray.800"
              href="https://instagram.com/playbackrocks"
            />
            <IconLink
              icon={<FaDiscord />}
              colorScheme="discord"
              color="discord.400"
              href={process.env.GATSBY_DISCORD_URL}
            />
            <IconLink
              icon={<FaTwitter />}
              colorScheme="twitter"
              href="https://twitter.com/playbackrocks"
            />
          </HStack>
        </div>
      </Stack>
    </Box>
  );
}
