import Header, {HEADER_HEIGHT} from './Header';
import PropTypes from 'prop-types';
import React, {useState} from 'react';
import hero from '../assets/images/hero.png';
import {Box, Button, Container, Heading, Text} from '@chakra-ui/react';
import {CONTAINER_PADDING_X} from '../utils';
import {FiArrowRight} from 'react-icons/fi';
import {Link as GatsbyLink} from 'gatsby';
import {useMount, useWindowScroll} from 'react-use';

export const HERO_PADDING_Y = [16, 20, 24];

export function HeroContent({title, description, children}) {
  return (
    <Box
      w={{
        base: 'full',
        md: 'calc(200% / 3)',
        lg: '50%'
      }}
    >
      <Heading as="h1" mb={{base: 3, md: 4}} size="3xl">
        {title}
      </Heading>
      <Text
        fontSize={{
          base: 'lg',
          md: 'xl'
        }}
      >
        {description}
      </Text>
      {children}
    </Box>
  );
}

HeroContent.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  children: PropTypes.node
};

export default function Hero({buttonSize, description}) {
  const {y} = useWindowScroll();
  const [now, setNow] = useState(Date.now());

  useMount(() => setNow(Date.now()));

  return (
    <>
      <Header
        key={now}
        isDark
        color="white"
        bgColor={y < 100 ? 'transparent' : 'gray.900'}
      />
      <Box
        bg="gray.900"
        color="white"
        bgImage={`url(${hero})`}
        bgSize="cover"
        bgPos="left"
        mt={HEADER_HEIGHT * -1}
        pt={HEADER_HEIGHT}
      >
        <Container
          maxW="container.xl"
          px={CONTAINER_PADDING_X}
          py={HERO_PADDING_Y}
        >
          <HeroContent
            title="A modern music video workflow"
            description={description}
          >
            <Button
              mt={{base: 5, md: 6}}
              size={buttonSize}
              rounded="full"
              colorScheme="pink"
              rightIcon={<FiArrowRight />}
              as={GatsbyLink}
              to="/projects"
            >
              Start creating
            </Button>
          </HeroContent>
        </Container>
      </Box>
    </>
  );
}

Hero.propTypes = {
  buttonSize: PropTypes.string,
  description: PropTypes.string.isRequired
};
