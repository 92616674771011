import PropTypes from 'prop-types';
import React from 'react';
import getShareImage from '@jlengstorf/get-share-image';
import {Helmet} from 'react-helmet';

export default function Seo({
  title,
  description,
  tagline = description,
  shareImageOptions
}) {
  const socialImage = getShareImage({
    title,
    tagline,
    cloudName: 'dybmuhvem',
    titleFont: 'Poppins',
    titleFontSize: 80,
    titleExtraConfig: '_bold_line_spacing_-49',
    taglineFont: 'Roboto',
    imagePublicID: 'pb_maioml',
    textColor: 'FFFFFF',
    ...shareImageOptions
  });
  return (
    <Helmet title={title}>
      <meta name="description" content={description} />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:site_name" content="Playback" />
      <meta property="og:image" content={socialImage} />
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:site" content="@playbackrocks" />
    </Helmet>
  );
}

Seo.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  tagline: PropTypes.string,
  shareImageOptions: PropTypes.object
};
