import Footer from './Footer';
import Header, {HEADER_HEIGHT} from './Header';
import Layout from './Layout';
import PageContent from './PageContent';
import PropTypes from 'prop-types';
import React from 'react';
import Seo from './Seo';
import support from '../assets/images/support.jpg';
import {
  Box,
  Button,
  Container,
  Flex,
  Heading,
  Text,
  useTheme
} from '@chakra-ui/react';
import {CONTAINER_PADDING_X, CONTAINER_PADDING_Y} from '../utils';
import {Link as GatsbyLink} from 'gatsby';
import {HERO_PADDING_Y, HeroContent} from './Hero';
import {IconContext} from 'react-icons';

export default function GuideLayout(props) {
  const {colors} = useTheme();
  const {title, description, summary} = props.pageContext.frontmatter;
  return (
    <Layout>
      <Seo
        title={title}
        description={description}
        shareImageOptions={{
          imagePublicID: 'pb2_gozxbe',
          textColor: colors.gray[800].slice(1).toUpperCase()
        }}
      />
      <Header bg="gray.50" />
      <Box bgImage={`url(${support})`} bgSize="cover" bgPos="left">
        <Container
          maxW="container.xl"
          px={CONTAINER_PADDING_X}
          py={HERO_PADDING_Y}
        >
          <HeroContent title={title} description={summary} />
        </Container>
      </Box>
      <Flex
        px={CONTAINER_PADDING_X}
        py={CONTAINER_PADDING_Y}
        maxW="container.xl"
        mx="auto"
        align="flex-start"
      >
        <IconContext.Provider
          value={{
            style: {
              display: 'inline',
              height: '1em',
              width: '1em',
              margin: '0 .05em 0 .1em',
              verticalAlign: '-.1em'
            }
          }}
        >
          <PageContent flexGrow="1">{props.children}</PageContent>
        </IconContext.Provider>
        <Box
          w="350px"
          flexShrink="0"
          ml="10"
          position="sticky"
          top={HEADER_HEIGHT + 8}
          display={{
            base: 'none',
            lg: 'block'
          }}
        >
          <Heading size="lg" as="h4" mb="2">
            Shoot like a pro
          </Heading>
          <Text mb="4">
            Get started for free or unlock extra features and higher file size
            limits for a one-time cost.
          </Text>
          <Button isFullWidth as={GatsbyLink} to="/#pricing">
            View pricing
          </Button>
        </Box>
      </Flex>
      <Footer />
    </Layout>
  );
}

GuideLayout.propTypes = {
  pageContext: PropTypes.object.isRequired,
  children: PropTypes.node.isRequired
};
