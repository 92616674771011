import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import GuideLayout from '../components/GuideLayout';
import { FiSettings, FiPlus, FiMoreHorizontal, FiFileText, FiSearch } from 'react-icons/fi';
import { MdSkipPrevious, MdSkipNext, MdRemoveCircle, MdAddCircle } from 'react-icons/md';
export const _frontmatter = {
  "title": "Getting started with Playback",
  "description": "This guide will cover the basics of using Playback.",
  "summary": "This guide will cover the basics of using Playback. Follow along and learn how to take your music video shoots to the next level."
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = GuideLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h2>{`Create a project`}</h2>
    <p>{`After you `}<a parentName="p" {...{
        "href": "/projects"
      }}>{`log in`}</a>{`, get started by creating your first project. Click on either of the pink "Add project" buttons. A form will appear prompting you to provide an audio file and name for your project (the artist and song title, for example).`}</p>
    <p>{`Any `}<a parentName="p" {...{
        "href": "https://en.wikipedia.org/wiki/HTML5_audio#Supported_audio_coding_formats"
      }}>{`web-ready audio format`}</a>{` is supported. Your file is access-controlled so only you can listen to it.`}</p>
    <p>{`You can edit a project's name, folder, or change its audio file by opening that project from the sidebar menu and clicking on the `}<FiSettings mdxType="FiSettings" />{` "Project settings" button on the right side of the project header.`}</p>
    <h2>{`Add blocks`}</h2>
    <p>{`Once you've created your project, you'll see a screen with a black play bar at the bottom. You can use the seek control to manually scrub around the song, but you can probably do that on whatever program you’re already using, too. We're here for the harder part: divvying that song up into the manageable pieces that make matching it to visuals a breeze.`}</p>
    <p>{`Click on the pink "Create a block" button at the bottom of the left sidebar or the `}<FiPlus mdxType="FiPlus" />{` "New block" button on the right side of the header. Two markers will show up on the play bar at the bottom of the screen. Drag these to set the in and out times for your current block.`}</p>
    <blockquote>
      <p parentName="blockquote">{`Tip: Set marker positions on the fly by playing the song and pressing the I (in) and O (out) keys to establish your block 🚀`}</p>
    </blockquote>
    <p>{`You can also name your block and add notes about the shot(s) that take place while it's playing. Add images to your notes by dragging and dropping them into the "Notes" input. Accepted file types include PNG, JPEG, and GIF.`}</p>
    <blockquote>
      <p parentName="blockquote">{`Tip: The notes field accepts any valid Markdown input, so you can easily add lists and use different text formatting. `}<a parentName="p" {...{
          "href": "https://github.com/adam-p/markdown-here/wiki/Markdown-Cheatsheet"
        }}>{`Click here`}</a>{` to learn about the basics of Markdown.`}</p>
    </blockquote>
    <p>{`To delete a block, click on the `}<FiMoreHorizontal mdxType="FiMoreHorizontal" />{` options button beside the block title, and then clicking the "Delete block" option. `}<strong parentName="p">{`You can't undo this action, so be careful!`}</strong></p>
    <h2>{`Play a block`}</h2>
    <p>{`When you have a block selected, you'll see its time range represented as a highlighted area on the play bar. From here, you can press play and hear only the part of the song selected in that block. Once a block finishes playing, the playhead will reset itself to the beginning of the block, so you can easily re-shoot a scene by pressing play again.`}</p>
    <p>{`Once you’ve created other blocks, you can toggle between them by clicking either of the skip buttons (`}<MdSkipPrevious mdxType="MdSkipPrevious" />{`, `}<MdSkipNext mdxType="MdSkipNext" />{`) on the play bar or using the arrow keys.`}</p>
    <h2>{`Next steps`}</h2>
    <p>{`Now that you've created a project and added some blocks, here are some advanced features that you might enjoy using:`}</p>
    <h3>{`Playback rate`}</h3>
    <p>{`Adjust the song’s playback rate by clicking either of the rate settings buttons (`}<MdRemoveCircle mdxType="MdRemoveCircle" />{`, `}<MdAddCircle mdxType="MdAddCircle" />{`) on the right side of the play bar. You can speed up or slow down the music to achieve cool effects. For example, if you play your song back at 200% speed, shoot a scene, and then stretch the clip out in your video editing program so that it plays at 50% speed, the artist will appear to perform in real time while the action in the background takes place in slow motion.`}</p>
    {
      /* Click the buffers button to apply 3-second time buffers to the start and end of a block. This can be useful when you need to show the artist mid-performance at the beginning of a sound block or when you need an extra few seconds to set context for the block at hand. Plus, your extended shots offer your video editor some extra footage to work with. */
    }
    <h3>{`Download as PDF`}</h3>
    <p>{`Download a printable PDF of a project's blocks with their respective time cues and notes sections by clicking the `}<FiFileText mdxType="FiFileText" />{` "Download PDF" button on the right side of the header. This can be useful for storyboarding or providing an outline of your project for the folks involved.`}</p>
    <h3>{`Searching blocks`}</h3>
    <p>{`You can search the content of a project by clicking the `}<FiSearch mdxType="FiSearch" />{` "Search blocks" button in the project page header. Add detail to your blocks' name, description, or notes so that you can easily find them later using full-text search. Select a search result to jump to the corresponding block.`}</p>
    {
      /* ## Offline use
      Sometimes, music videos are shot out of range of a reliable wireless network. Fortunately, this isn't a problem for Playback. The website is available offline, but with limited functionality. You won't be able to create, update, or delete projects or blocks, but you can view and play ones that you've already loaded on a given device. Just navigate to app.playback.rocks on your laptop, tablet, or phone, and get to work!
      You can also install the website as an app on your Android or iOS mobile device for quick and easy access from the home screen or app drawer. */
    }

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      