import React from 'react';
import {
  Box,
  Heading,
  Link,
  ListItem,
  OrderedList,
  Text
} from '@chakra-ui/react';
import {CONTAINER_GAP, CONTAINER_PADDING_Y} from '../utils';
import {MDXProvider} from '@mdx-js/react';

const MARGIN_Y = {base: 3, md: 4};

const components = {
  h2(props) {
    return (
      <Heading mt={CONTAINER_PADDING_Y} mb={MARGIN_Y} size="2xl" {...props} />
    );
  },
  h3(props) {
    return (
      <Heading mt={CONTAINER_GAP} as="h3" mb={MARGIN_Y} size="xl" {...props} />
    );
  },
  p(props) {
    return <Text mb={MARGIN_Y} lineHeight={{md: 'tall'}} {...props} />;
  },
  ol(props) {
    return (
      <OrderedList
        my={MARGIN_Y}
        spacing={{md: 3}}
        styleType="lower-alpha"
        {...props}
      />
    );
  },
  li: ListItem,
  a(props) {
    return <Link color="pink.500" {...props} />;
  }
};

export default function PageContent(props) {
  return (
    <MDXProvider components={components}>
      <Box
        as="main"
        id="mdx"
        fontSize={{md: 'lg'}}
        sx={{
          '> :first-child': {
            mt: 0
          },
          'ol > li > ol': {
            pl: 8,
            listStyleType: 'lower-roman'
          }
        }}
        {...props}
      />
    </MDXProvider>
  );
}
